import React, { useEffect, useState } from 'react';
import { Card, Col, Pagination, Popconfirm, Row, Tooltip } from 'antd';
import { useIntl } from 'react-intl';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { RiArrowLeftLine, RiErrorWarningLine } from 'react-icons/ri';
import { Filter } from 'iconsax-react';
import isEmpty from 'lodash/isEmpty';
import urlPageAchievements from '../../../../../urls/urlPageAchievements';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import appColors from '../../../../../const/colors';
import { imageStatuses } from '../../../../../const/imageStatuses';
import { useGetAchievementQuery, useUpdateAchievementMutation } from '../../../api/achievementsApiSlice';
import useQueryParams from '../../../../../app/hooks/useQueryParams';
import useToggle from '../../../../../app/hooks/useToggle';
import useScrollToImagesOnSearch from '../../../hooks/useScrollToImagesOnSearch';
import applyFilter from '../../../../../lib/applyFilter';
import resetFilter from '../../../../../lib/resetFilter';
import handleResponse from '../../../../../lib/handleResponse';
import BreadCrumbs from '../../../../../layout/components/breadcrumbs';
import TotalLabel from '../../../../../components/TotalLabel';
import ActionButton from '../../../../../layout/components/action-button';
import FormImagesFilter from '../../../forms/FormImagesFilter';
import Sidebar from '../../../../../components/Sidebar';
import Spinner from '../../../../../components/Spinner';
import FormCreateEditAchievement from '../../../forms/FormCreateEditAchievement';
import ContentCard from '../../../components/ContentCard';
import EmptyDataPlaceholder from '../../../../../components/EmptyDataPlaceholder';
import { useAttachImageMutation, useGetImagesQuery } from '../../../api/imagesApiSlice';



const PageAchievementsEdit = () => {
  const intl = useIntl();
  const { id } = useParams();
  const navigate = useNavigate();
  const [ isFilterOpen, toggleFilterSidebar ] = useToggle();
  const [ searchQueryParams, setSearchParams ] = useSearchParams();
  const [ achievementInitialValues, setAchievementInitialValues ] = useState({});
  const [ achievementInitialErrors, setAchievementInitialErrors ] = useState({});

  const searchFilterFields = [ 'id', 'folder', 'category', 'main_category', 'image_type', 'tags', 'taps_from', 'taps_to', 'colors_from', 'colors_to', 'imported_from', 'imported_to', 'released_from', 'released_to' ];

  const {
    pagination,
    searchParams,
    initFilterValues,
    setInitFilterValues,
    handleChangeTableParams,
  } = useQueryParams({
    searchFilterFields,
    paginationInit: { page: 1, limit: 12 } });

  const {
    data: { data: achievement } = { data: {} },
    isFetching: isAchievementFetching,
  } = useGetAchievementQuery(id);

  const { data: {
    data: images = [],
    pagination: dataPagination = {},
  } = { data: [], pagination: {} }, isFetching } = useGetImagesQuery({
    queryParams: searchParams.toString().includes('search') ? `${searchParams.toString()};status:ready_for_release` : `${searchParams.toString()}&search=status:ready_for_release`,
  });

  const [ updateAchievement, { isLoading: isUpdatingAchievement } ] = useUpdateAchievementMutation();
  const [ attachImage, { isLoading: isAttaching } ] = useAttachImageMutation();

  const attachedImagesCount = achievement?.images?.data?.length;
  const imagesRef = useScrollToImagesOnSearch(images, searchQueryParams);

  useEffect(() => {
    if (!achievementInitialValues.name && achievement?.name) {
      setAchievementInitialValues({
        name: achievement?.name,
        code: achievement?.code,
        description: achievement?.description,
      });
    }
  }, [ achievement ]);

  const handleSubmit = (values) => {
    const action = updateAchievement({ id, payload: values });

    action
      .unwrap()
      .then(() => {
        setAchievementInitialValues({
          name: values.name,
          code: achievement?.code,
          description: values.description,
        });
      })
      .then(() => handleResponse('success', intl, 'ui-general-updated-successfully'))
      .catch((error) => {
        handleResponse(
          'error',
          intl,
          'ui-general-update-failed',
          [
            () => setAchievementInitialErrors(error?.data?.errors),
          ],
          error,
        );
      });
  };

  const handleAttach = (imageId, status) => {
    const data = {
      attached_to: id,
      type: imageStatuses.achievement,
      status,
    };

    attachImage({ id: imageId, payload: data })
      .unwrap()
      .then(() => handleResponse('success', intl, 'achievements-attach-success'))
      .catch((error) => handleResponse('error', intl, 'achievements-attach-failed', [], error));
  };

  const actions = (item, isDetach = false) => {
    return [ (
      <Tooltip
        key="download"
        placement="bottom"
        title={<IntlMessages id={isDetach ? 'ui-general-detach-image' : 'ui-general-attach-image'} />}
      >
        {isDetach ? (
          <Popconfirm
            title={<IntlMessages id="achievements-detach-confirm-message" />}
            placement="top"
            onConfirm={() => {
              handleAttach(item.id, imageStatuses.ready_for_release);
            }}
            okText={<IntlMessages id="ui-general-yes" />}
            cancelText={<IntlMessages id="ui-general-no" />}
            icon={<RiErrorWarningLine className="remix-icon hp-text-color-primary-1" />}
            okButtonProps={{ danger: true }}
          >
            <DeleteOutlined style={{ color: appColors.orange }} />
          </Popconfirm>
        ) : (
          <Popconfirm
            title={<IntlMessages id="achievements-attach-confirm-message" />}
            placement="top"
            onConfirm={() => {
              handleAttach(item.id, imageStatuses.achievement);
            }}
            okText={<IntlMessages id="ui-general-yes" />}
            cancelText={<IntlMessages id="ui-general-no" />}
            icon={<RiErrorWarningLine className="remix-icon hp-text-color-primary-1" />}
          >
            <PlusOutlined style={{ color: appColors.mediumGreen }} />
          </Popconfirm>
        )}
      </Tooltip>
    ) ];
  };

  const showTotal = (total) => <TotalLabel total={total} />;
  const isLoading = isAchievementFetching || isUpdatingAchievement || isFetching || isAttaching;

  return (
    <>
      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-print-none hp-mb-32"
        align="middle"
      >
        <BreadCrumbs
          breadCrumbParent={
            <Link to={urlPageAchievements()}>
              <IntlMessages id='achievements-breadcrumbs' />
            </Link>
          }
          breadCrumbActive={achievement?.name}
        />
        <Col>
          <ActionButton
            icon={<RiArrowLeftLine className="hp-mr-8" size={18} />}
            onClick={() => {
              navigate(urlPageAchievements());
            }}
            title={<IntlMessages id='ui-general-back-to-list' />}
          />
        </Col>
      </Row>

      <Spinner spinning={isLoading}>
        <Row gutter={[ 32, 32 ]} className="hp-mb-32">
          <Col span={24}>
            <Card className="hp-border-color-black-40 hp-card-6">
              <FormCreateEditAchievement
                isEdit
                initialValues={achievementInitialValues}
                onSubmit={handleSubmit}
                onCancel={() => {}}
                isSubmitting={isUpdatingAchievement}
                initialErrors={achievementInitialErrors}
              />
            </Card>
          </Col>
        </Row>

        <Row gutter={[ 32, 32 ]} ref={imagesRef}>
          <Sidebar
            visible={isFilterOpen}
            toggleSidebar={toggleFilterSidebar}
            width={600}
          >
            <FormImagesFilter
              isSubmitting={isFetching}
              initialValues={initFilterValues}
              excludedFields={[ 'status', 'released_from', 'released_to' ]}
              onCancel={() => {
                resetFilter({
                  searchQueryParams,
                  setSearchParams,
                  setInitFilterValues,
                });
              }}
              onSubmit={(values) => {
                applyFilter({
                  values,
                  searchQueryParams,
                  searchFilterFields,
                  setSearchParams,
                  toggleFilterSidebar,
                });
              }}
            />
          </Sidebar>

          <Col xs={24} lg={12}>
            <Card
              width="100%"
              title={(
                <div className="hp-d-flex hp-d-flex-between hp-align-items-center">
                  <IntlMessages id="ui-general-ready-to-release" />
                  <ActionButton
                    title=""
                    isActive={!isEmpty(initFilterValues)}
                    className='hp-mr-sm-8'
                    icon={<Filter size={18} />}
                    onClick={toggleFilterSidebar}
                  />
                </div>
              )}
            >
              {images?.length ? (
                <Row gutter={[ 16, 32 ]}>
                  {images?.map((item) => {
                    return (
                      <Col key={item.id} className='gutter-row' xs={24} sm={12} xxl={8}>
                        <ContentCard
                          data={item}
                          onClick={() => {}}
                          actions={actions(item)}
                        />
                      </Col>
                    );
                  })}
                  <Col span={24}>
                    {dataPagination.total && (
                      <Pagination
                        total={dataPagination.total}
                        showTotal={showTotal}
                        pageSize={pagination.limit}
                        current={pagination.page}
                        pageSizeOptions={[ '12', '24', '48', '96' ]}
                        onChange={(page, size) => {
                          handleChangeTableParams({
                            current: page,
                            pageSize: size,
                          }, {}, {});
                        }}
                      />
                    )}
                  </Col>
                </Row>
              ) : (
                <EmptyDataPlaceholder placeholder={<IntlMessages id="ui-general-no-images-found" />} />
              )}
            </Card>
          </Col>
          <Col xs={24} lg={12}>
            <Card
              width="100%"
              title={
                <div className="hp-d-flex hp-d-flex-between hp-align-items-center">
                  <IntlMessages id="achievements-title" />
                  <TotalLabel total={attachedImagesCount} />
                </div>
              }
            >
              <Row gutter={[ 16, 32 ]}>
                {attachedImagesCount ?
                  achievement?.images?.data?.map((item) => {
                    return (
                      <Col key={item.id} className='gutter-row' xs={24} sm={12} xxl={8}>
                        <ContentCard
                          data={item}
                          onClick={() => {}}
                          actions={actions(item, true)}
                        />
                      </Col>
                    );
                  }) : (
                    <EmptyDataPlaceholder placeholder={<IntlMessages id="achievements-no-attached-images" />} />
                  )}
              </Row>
            </Card>
          </Col>
        </Row>
      </Spinner>
    </>
  );
};

export default PageAchievementsEdit;

import React from 'react';
import { Form, FormItem, Input, ResetButton, Select, SubmitButton } from 'formik-antd';
import { Formik } from 'formik';
import { Col, DatePicker, Row, Tooltip } from 'antd';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import moment from 'moment';
import { InfoCircleOutlined } from '@ant-design/icons';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import { categoriesStatusOptions } from './FormCategoriesFilter.const';
import makePreventOnKeyDown from '../../../../lib/makePreventOnKeyDown';
import Spinner from '../../../../components/Spinner';



const FormCategoriesFilter = ({
  initialValues,
  onSubmit,
  onCancel,
  isSubmitting,
}) => {
  const intl = useIntl();

  return (
    <Formik
      enableReinitialize
      isSubmitting
      initialValues={initialValues}
      onSubmit={onSubmit}
      onReset={onCancel}
    >
      {({ isValid, dirty, values, setFieldValue }) => {
        return (
          <Form
            className="images-filter w-full"
            layout="vertical"
            onKeyDown={makePreventOnKeyDown}
          >
            <Spinner spinning={isSubmitting}>
              <Row gutter={16}>
                <Col span={12}>
                  <FormItem
                    label={
                      <Row className='w-full' justify="space-between" align="middle">
                        <IntlMessages id="ui-general-id" />
                        <Tooltip placement="topRight" title={<IntlMessages id="ui-general-filter-id-key-help" />}>
                          <InfoCircleOutlined />
                        </Tooltip>
                      </Row>
                    }
                    className="ant-form-item-col stretched"
                    name='client_id'
                  >
                    <Input
                      name="client_id"
                      placeholder={intl.formatMessage({ id: 'ui-general-ids-placeholder' })}
                    />
                  </FormItem>
                </Col>

                <Col span={12}>
                  <FormItem
                    label={intl.formatMessage({ id: 'ui-general-name' })}
                    name='name'
                  >
                    <Input
                      name="name"
                      placeholder={intl.formatMessage({ id: 'ui-general-specify-name' })}
                    />
                  </FormItem>
                </Col>

                <Col span={24}>
                  <FormItem
                    className='ant-form-item-col'
                    name="status"
                    label={<IntlMessages id="ui-general-status" />}
                  >
                    <Select
                      allowClear
                      name="status"
                      options={categoriesStatusOptions}
                      placeholder={<IntlMessages id="ui-general-choose-status" />}
                    />
                  </FormItem>
                </Col>

                <Col span={12}>
                  <FormItem
                    label={intl.formatMessage({ id: 'ui-general-created-from' })}
                    name='created_from'
                  >
                    <DatePicker
                      name="created_from"
                      value={values?.created_from ? moment(values.created_from) : null}
                      onChange={(date, dateString) => {
                        setFieldValue('created_from', dateString);
                      }}
                    />
                  </FormItem>
                </Col>

                <Col span={12}>
                  <FormItem
                    label={intl.formatMessage({ id: 'ui-general-created-to' })}
                    name='created_to'
                  >
                    <DatePicker
                      name="created_to"
                      value={values?.created_to ? moment(values.created_to) : null}
                      onChange={(date, dateString) => {
                        setFieldValue('created_to', dateString);
                      }}
                    />
                  </FormItem>
                </Col>
              </Row>

              <Row gutter={[ 16, 16 ]} justify='end'>
                <Col>
                  <ResetButton disabled={false} loading={isSubmitting}>
                    <IntlMessages id='ui-general-reset' />
                  </ResetButton>
                </Col>

                <Col>
                  <SubmitButton
                    loading={isSubmitting}
                    disabled={!isValid || !dirty}
                  >
                    <IntlMessages id="ui-general-apply" />
                  </SubmitButton>
                </Col>
              </Row>
            </Spinner>
          </Form>
        );
      }}
    </Formik>
  );
};

FormCategoriesFilter.propTypes = {
  initialValues: PropTypes.shape({}).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default FormCategoriesFilter;

import isString from 'lodash/isString';
import map from 'lodash/map';
import trim from 'lodash/trim';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '../const/system';



const parseDate = (value) => {
  const dateRegexPattern = /^\d{4}-\d{2}-\d{2}$/;

  if (dateRegexPattern.test(value)) {
    const parsedDate = dayjs(value, DATE_FORMAT, true);

    return parsedDate.isValid() ? parsedDate.format(DATE_FORMAT) : null;
  }
  return null;
};

const parseNumber = (value) => {
  const trimmedValue = value.trim();

  return !isNaN(trimmedValue) && trimmedValue !== '' ? parseFloat(trimmedValue) : null;
};

const parseBoolean = (value) => {
  if (value === 'true') return true;
  if (value === 'false') return false;
  return null;
};

const parseArrayFromString = (value) => {
  return isString(value) && value.includes(',') ? map(value.split(','), trim) : null;
};

const convertStringsToNumbersInArray = (arr) => {
  return arr.map((item) => !isNaN(item) && item.trim() !== '' ? parseFloat(item) : item);
};

const parseQueryParam = (value) => {
  switch (value) {
    case parseBoolean(value) !== null:
      return parseBoolean(value);

    case parseArrayFromString(value) !== null:
      return convertStringsToNumbersInArray(parseArrayFromString(value));

    case parseDate(value) !== null:
      return parseDate(value);

    case parseNumber(value) !== null:
      return parseNumber(value);

    default:
      return value;
  }
};

export default parseQueryParam;

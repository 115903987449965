import React from 'react';
import { Formik } from 'formik';
import { Col, DatePicker, Row, Tooltip } from 'antd';
import { Form, FormItem, Input, ResetButton, SubmitButton, Select } from 'formik-antd';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import moment from 'moment';
import { InfoCircleOutlined } from '@ant-design/icons';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import Yup from '../../../../vendor/yup';
import useDatepickerHandler from '../../../../app/hooks/useDatepickerHandler';
import { DATE_FORMAT, statusOptions } from '../../../../const/system';



const validationSchema = Yup.object().shape({
  id: Yup.string().min(1),
  title: Yup.string(),
  status: Yup.string(),
  created_from: Yup.date(),
  created_to: Yup.date(),
  content_type: Yup.string(),
});

const FormFilterTemplates = ({
  initialValues,
  contentTypes,
  onSubmit,
  onCancel,
  isSubmitting,
}) => {
  const intl = useIntl();
  const { onBlur } = useDatepickerHandler();

  const contentTypesOptions = contentTypes?.map((contentType) => {
    return {
      label: intl.formatMessage({ id: `structures-source-${contentType.name}` }),
      value: contentType.name,
    };
  });

  const disabledDate = (current) => {
    return current && current > moment();
  };

  return (
    <Formik
      enableReinitialize
      isSubmitting
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      onReset={onCancel}
    >
      {({ isValid, dirty, values, setFieldValue }) => {
        return (
          <Form layout="vertical" >
            <FormItem
              name='id'
              className="ant-form-item-col stretched"
              label={
                <Row className='w-full' justify="space-between" align="middle">
                  <IntlMessages id="ui-general-id" />
                  <Tooltip placement="topRight" title={<IntlMessages id="ui-general-filter-id-key-help" />}>
                    <InfoCircleOutlined />
                  </Tooltip>
                </Row>
              }
            >
              <Input
                name="id"
                placeholder={intl.formatMessage({ id: 'templates-filter-form-id-placeholder' })}
              />
            </FormItem>

            <FormItem
              label={intl.formatMessage({ id: 'ui-general-title' })}
              name='title'
            >
              <Input
                name="title"
                placeholder={intl.formatMessage({ id: 'templates-filter-form-title-placeholder' })}
              />
            </FormItem>

            <FormItem
              label={intl.formatMessage({ id: 'ui-general-content-type' })}
              name='content_type'
            >
              <Select
                allowClear
                name="content_type"
                options={contentTypesOptions}
                placeholder={intl.formatMessage({ id: 'ui-general-choose-content-type' })}
              />
            </FormItem>

            <FormItem
              label={intl.formatMessage({ id: 'ui-general-status' })}
              name='status'
            >
              <Select
                allowClear
                name="status"
                value={values?.status?.toString() ?? null}
                options={statusOptions}
                placeholder={intl.formatMessage({ id: 'ui-general-choose-status' })}
              />
            </FormItem>

            <Row gutter={16}>
              <Col span={12}>
                <FormItem
                  label={intl.formatMessage({ id: 'ui-general-created-from' })}
                  name='created_from'
                >
                  <DatePicker
                    name='created_from'
                    format={DATE_FORMAT}
                    showToday
                    disabledDate={disabledDate}
                    value={values?.created_from ? moment(values.created_from) : null}
                    onChange={async (date, dateString) => {
                      await setFieldValue('created_from', dateString);
                    }}
                    onBlur={async ({ target }) => {
                      await onBlur('created_from', target.value, setFieldValue);
                    }}
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label={intl.formatMessage({ id: 'ui-general-created-to' })}
                  name='created_to'
                >
                  <DatePicker
                    name='created_to'
                    format={DATE_FORMAT}
                    disabledDate={disabledDate}
                    value={values?.created_to ? moment(values.created_to) : null}
                    onChange={async (date, dateString) => {
                      await setFieldValue('created_to', dateString);
                    }}
                    onBlur={async ({ target }) => {
                      await onBlur('created_to', target.value, setFieldValue);
                    }}
                  />
                </FormItem>
              </Col>
            </Row>

            <Row gutter={[ 16, 16 ]} justify='end'>
              <Col>
                <ResetButton disabled={false}>
                  <IntlMessages id='ui-general-reset' />
                </ResetButton>
              </Col>

              <Col>
                <SubmitButton
                  type="primary"
                  loading={isSubmitting}
                  disabled={!isValid || !dirty}
                >
                  <IntlMessages id="ui-general-apply" />
                </SubmitButton>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

FormFilterTemplates.propTypes = {
  initialValues: PropTypes.object.isRequired,
  contentTypes: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};


export default FormFilterTemplates;

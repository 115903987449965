import React, { useState } from 'react';
import { Card, Col, Input, Modal, Row, Table } from 'antd';
import { RiAddLine, RiCloseFill } from 'react-icons/ri';
import isObject from 'lodash/isObject';
import isEmpty from 'lodash/isEmpty';
import { Search } from 'react-iconly';
import { useIntl } from 'react-intl';
import basePagination from '../../../../../const/pagination';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import { getLanguageListTableColumns } from './PageLanguages.const';
import { HasRights } from '../../../../../components/HasRights/HasRights';
import { Permissions } from '../../../../../const/permissions';
import useQueryParams from '../../../../../app/hooks/useQueryParams';
import useToggle from '../../../../../app/hooks/useToggle';
import handleResponse from '../../../../../lib/handleResponse';
import makeTableLoadingObject from '../../../../../lib/makeTableLoadingObject';
import ActionButton from '../../../../../layout/components/action-button';
import Spinner from '../../../../../components/Spinner';
import BreadCrumbs from '../../../../../layout/components/breadcrumbs';
import FormCreateEditLanguage from '../../../forms/FormCreateEditLanguage';
import {
  useCreateLanguageMutation,
  useGetLanguagesQuery,
  useUpdateLanguageMutation,
} from '../../../api/languagesApiSlice';



const PageLanguages = () => {
  const intl = useIntl();
  const [ isActiveLanguageModal, toggleModal ] = useToggle();
  const [ createLanguageErrors, setFormErrors ] = useState({});
  const [ modalInitValues, setModalInitValues ] = useState({});

  const {
    pagination,
    search,
    setSearchTerm,
    searchParams,
    handleChangeTableParams,
  } = useQueryParams({ searchFields: [ 'name', 'code' ] });

  const { data = { data: [], pagination: {} }, isLoading } = useGetLanguagesQuery({
    queryParams: searchParams.toString(),
  });

  const [ updateLanguage, { isLoading: isUpdateLanguageLoading } ] = useUpdateLanguageMutation();
  const [ createLanguage, { isLoading: isCreateLanguageLoading } ] = useCreateLanguageMutation();


  const onOpenCreateModal = () => {
    setModalInitValues({});
    toggleModal();
  };

  const onOpenUpdateModal = (data) => {
    setModalInitValues(data);
    toggleModal();
  };

  const onCancelModal = () => {
    toggleModal();
    setFormErrors({});
  };

  const handleSubmitForm = async (values) => {
    let action;

    if (!modalInitValues?.id) {
      action = createLanguage(values);
    } else {
      action = updateLanguage({ id: modalInitValues.id,
        values: {
          name: values.name,
          code: values.code,
        },
      });
    }

    action.unwrap()
      .then(() => handleResponse(
        'success',
        intl,
        !modalInitValues?.id ? 'ui-general-created-successfully' : 'ui-general-updated-successfully',
        [ toggleModal ],
      ))
      .catch((error) => handleResponse(
        'error',
        intl,
        !modalInitValues?.id ? 'ui-general-create-failed' : 'ui-general-update-failed',
        [
          () => {
            if (isObject(error?.data?.errors)) {
              setFormErrors(error.data.errors);
            }
          },
        ],
        error,
      ));
  };

  const handleStatusChange = (id, values) => {
    updateLanguage({ id, values });
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <>
      <Modal
        title={<IntlMessages id={!modalInitValues?.id ? 'ui-general-create' : 'ui-general-edit'} />}
        width={416}
        centered
        destroyOnClose
        visible={isActiveLanguageModal}
        onCancel={onCancelModal}
        footer={null}
        closeIcon={
          <RiCloseFill className="remix-icon text-color-black-100" size={24} />
        }
      >
        <Spinner spinning={isCreateLanguageLoading || isUpdateLanguageLoading}>
          <FormCreateEditLanguage
            initialValues={modalInitValues}
            formErrors={createLanguageErrors}
            onSubmit={handleSubmitForm}
            onCancel={onCancelModal}
            isSubmitting={isCreateLanguageLoading}
          />
        </Spinner>
      </Modal>

      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-print-none hp-mb-32"
        align="middle"
      >
        <BreadCrumbs breadCrumbActive={<IntlMessages id='languages-breadcrumbs' />} />

        <Col md={15} span={24}>
          <Row justify="end" align="middle" gutter={[ 16, 16 ]}>
            <Col md={12} span={24}>
              <Input
                allowClear={!isEmpty(search)}
                placeholder={intl.formatMessage({ id: 'languages-search-by' })}
                prefix={<Search set="curved" size={16} className="hp-text-color-black-80" />}
                value={search}
                onChange={handleSearch}
              />
            </Col>

            {HasRights([ Permissions.TRANSLATIONS.LANGUAGES.LANGUAGES_LIST.CREATE ]) && (
              <Col>
                <ActionButton
                  title={<IntlMessages id="ui-general-create" />}
                  icon={<RiAddLine className="btn-icon-mr-1" />}
                  onClick={onOpenCreateModal}
                />
              </Col>
            )}
          </Row>
        </Col>
      </Row>

      <Row gutter={32}>
        <Col span={24}>
          <Card className="hp-border-color-black-40 hp-card-6">
            <Table
              sticky
              loading={makeTableLoadingObject(isLoading)}
              rowKey="id"
              columns={getLanguageListTableColumns(handleStatusChange, onOpenUpdateModal)}
              dataSource={data.languages}
              onChange={handleChangeTableParams}
              pagination={{
                ...basePagination,
                current: pagination.page,
                pageSize: pagination.limit,
                total: data.pagination?.total,
              }}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};


export default PageLanguages;

import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { omit } from 'lodash/object';
import isArray from 'lodash/isArray';
import useDebounce from './useDebounce';
import getQueryParams from '../../lib/getQueryParams';
import parseQueryParam from '../../lib/parseQueryParam';



const isUsedSearch = (queryObj = {}, searchFields = []) => {
  if (!queryObj.search) {
    return false;
  }

  const searchKeys = queryObj.search.split(';').map((item) => item.split(':')[0]).filter(Boolean);

  return searchKeys.every((key) => searchFields.includes(key));
};


const useQueryParams = ({ searchFields, paginationInit = { page: 1, limit: 10 } } = {}) => {
  // This param need to set when page has "fast search" and disable auto reset filter
  const [ resetIsManuallyTriggered, setResetManuallyTriggered ] = useState(true);
  const [ searchParams, setSearchParams ] = useSearchParams();
  const [ initFilterValues, setInitFilterValues ] = useState({});

  const initPagination = {
    page: Number.parseInt(searchParams.get('page') || paginationInit.page, 10),
    limit: Number.parseInt(searchParams.get('limit') || paginationInit.limit, 10),
  };

  if (searchParams.has('sortedBy') && (searchParams.get('sortedBy') !== 'null')) {
    initPagination.sortedBy = searchParams.get('sortedBy');
    initPagination.orderBy = searchParams.get('orderBy');
  }

  const [ pagination, setPagination ] = useState(initPagination);
  const [ searchTerm, setSearchTerm ] = useState('');

  const queryParams = getQueryParams(window.location.search);
  const searchTermQuery = useDebounce(searchTerm.trim());
  const isSearch = isUsedSearch(queryParams, searchFields);


  // PAGINATION
  useEffect(() => {
    setPagination((prevState) => {
      return {
        ...prevState,
        page: Number.parseInt(searchParams.get('page') || paginationInit.page, 10),
      };
    });
  }, [ searchParams ]);


  // FILTER
  useEffect(() => {
    if (queryParams.search) {
      queryParams.search.split(';').forEach((item) => {
        const [ key, value ] = item.split(':');

        if (value !== '') {
          if (isSearch && !searchTermQuery.length) {
            setSearchTerm(value);
          }
          if (key === 'template.template_content_type_id') {
            initFilterValues.template_type = value;
          } else {
            initFilterValues[key] = parseQueryParam(value);
          }
        }
      });

      setInitFilterValues(initFilterValues);
      setSearchParams(queryParams, { replace: true });
    }
  }, [ initFilterValues ]);


  // SEARCH
  useEffect(() => {
    Array.from(searchParams.entries())
      .forEach(([ key, value ]) => {
        if (!searchTermQuery.length && key === 'search') {
          return;
        }

        queryParams[key] = key === 'page' ? 1 : value;
      });

    if (searchTermQuery.length && searchFields?.length) {
      queryParams.search = searchFields.map((field) => `${field}:${searchTermQuery}`).join(';');
      setSearchParams(queryParams, { replace: true });
    }

    if (isSearch && !searchTermQuery.length) {
      const { search, ...restParams } = queryParams;

      setSearchParams(restParams, { replace: true });
    }
    setInitFilterValues({});
  }, [ searchTermQuery ]);


  // PAGINATION
  useEffect(() => {
    let preparedPagination = { ...pagination };

    preparedPagination = omit(preparedPagination, [ 'filters' ]);

    Object.entries(pagination.filters ?? {}).forEach(([ key, value ]) => {
      if (isArray(value)) {
        preparedPagination[`${key}[]`] = value;
      } else if (value !== null) {
        preparedPagination[key] = value;
      }
    });

    setSearchParams((prevState) => {
      let queryParams = {};

      Array.from(prevState.entries())
        .forEach(([ key, value ]) => {
          if (pagination?.filters?.hasOwnProperty(key) || pagination?.filters?.hasOwnProperty(key.replace('[]', ''))) {
            return;
          }

          queryParams[key] = value;
        });

      if (preparedPagination.sortedBy === 'null' || preparedPagination.sortedBy === 'undefined' || !preparedPagination.sortedBy) {
        preparedPagination = omit(preparedPagination, [ 'sortedBy', 'orderBy' ]);
        queryParams = omit(queryParams, [ 'sortedBy', 'orderBy' ]);
      }

      return { ...queryParams, ...preparedPagination };
    }, { replace: true });
  }, [ pagination ]);


  const onTableParamsChange = (pagination, filters, sorter) => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
    let updatedParams = {
      page: pagination.current,
      limit: pagination.pageSize,
      sortedBy: sorter.order,
      orderBy: sorter.field,
      filters,
    };

    if (sorter.order === 'ascend') {
      updatedParams.sortedBy = 'asc';
    } else if (sorter.order === 'descend') {
      updatedParams.sortedBy = 'desc';
    } else if (!sorter.order) {
      updatedParams = omit(updatedParams, [ 'sortedBy', 'orderBy' ]);
    }

    setPagination(updatedParams);
  };

  return {
    pagination,
    searchParams,
    initFilterValues,
    setInitFilterValues,
    setSearchTerm,
    search: searchTerm,
    handleChangeTableParams: onTableParamsChange,
    resetIsManuallyTriggered,
    setResetManuallyTriggered,
  };
};

export default useQueryParams;
